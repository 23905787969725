






































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FilterComponent from '@/components/form/FilterComponent';
import StructureFilter from '@/components/form/StructureFilter.vue';

import RiskCharacter from '@models/proRisk/RiskCharacter';
import {
  FrequenciesEnumName,
  MastersEnumName,
  QuotationEnumName, SeriousnessEnumName,
} from '@modules/professional-risk/types/enum';

const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: { StructureFilter },
})
export default class ProRiskSynthesisFilter extends FilterComponent {
  @riskCharacterRepository.Getter('getAll')
  riskCharacters!: RiskCharacter[];

  filters = {
    affectation: '',
    riskCharacter: '',
    criticality: '',
    frequency: '',
    seriousness: '',
    mastery: '',
  };

  criticalityEnum = QuotationEnumName;
  frequenciesEnum = FrequenciesEnumName;
  mastersEnum = MastersEnumName;
  seriousnessEnum = SeriousnessEnumName;
}
