






































































import { Vue, Prop, Component } from 'vue-property-decorator';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';
import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import { TableColumn } from 'element-ui/types/table-column';
import { lazyInject } from '@/ioc/container';
import Risk from '@models/proRisk/Risk';
import {
  FrequenciesEnumName,
  MastersEnumName,
  SeriousnessEnumName,
} from '@modules/professional-risk/types/enum';

@Component
export default class SynthesisTableView extends Vue {
  @Prop({ type: Boolean }) loading!: boolean;
  @Prop({ type: Array }) risks!: Array<Risk>;

  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  frequencies = FrequenciesEnumName;
  seriousness = SeriousnessEnumName;
  masters = MastersEnumName;

  sortCharacter(a: Risk, b: Risk) {
    if (a.character && b.character) {
      return (a.character.label > b.character.label) ? 1 : -1;
    }
    return 1;
  }

  cellClassName(cell: { row: Risk, column: TableColumn, rowIndex: number, columnIndex: number }) {
    const { row, column, columnIndex } = cell;
    if (column.label === 'Niv de risque') {
      return this.riskService.color(row);
    }
    return '';
  }
}
