















import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';

import ProFolder from '@models/proRisk/ProFolder';
import Risk from '@models/proRisk/Risk';


import ProRiskSynthesisFilter
  from '@modules/professional-risk/components/form/ProRiskSynthesisFilter.vue';
import { PaginatedData } from '@/types/paginatedData';
import { PrintActionInterface } from '@/types/breadcrumb';
import SynthesisTable from '@modules/professional-risk/components/SynthesisTableView.vue';
import _ from 'lodash';
import Routing from 'fos-routing';

const riskProModule = namespace('riskPro');
const riskRepository = namespace('repositories/risk');

@Component({
  components: {
    SynthesisTable,
    Layout,
    ProRiskSynthesisFilter,
  },
})
export default class SynthesisView extends Vue {
  @riskProModule.Getter('getActiveProFolder')
  proFolder!: ProFolder | null;

  @riskRepository.Getter('getAll')
  risks!: Risk[];

  @riskRepository.Action('search')
  fetchRisks!: (payload: { folder: ProFolder, filters: any }) => Promise<PaginatedData<Risk>>;

  loading: boolean = false;

  filters: any = {
    riskCharacter: '',
    criticality: '',
    frequency: '',
    seriousness: '',
    mastery: '',
    job: '',
    unit: '',
    limit: 1000,
  };

  get printAction(): PrintActionInterface | null {
    return {
      href: this.pdfLink,
    };
  }

  get pdfLink() {
    if (this.proFolder) {
      const params = { ..._.omit(this.filters, ['limit', 'page']), id: this.proFolder.id };

      return Routing.generate('pdf_pro_folder_evrp', params);
    }

    return '';
  }

  @Watch('filters', { immediate: true, deep: true })
  async onFilterChange() {
    if (this.proFolder && !this.loading) {
      this.loading = true;
      await this.fetchRisks({ folder: this.proFolder, filters: this.filters });
      this.loading = false;
    }
  }
}
